<template>
  <div class="register-select app_content">
    <div class="warp_body">

      <div class="iconDiv">
        <van-icon name="warning-o" color="red" size="70px"/>
      </div>
      <div class="textDiv">
        {{ text }}
      </div>
      <div class="thankDiv">
        {{ thankText }}
      </div>

      <div class="btnDiv">
        <van-button type="warning" size="large" round @click="cancelAccount">申请注销</van-button>
      </div>

    </div>

  </div>
</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import API from "../api/User";
import req from "../request/request";
import router from "../router";


export default {
  name: 'CancelAccount',

  setup() {
    onBeforeMount(() => {
      let userinfo = JSON.parse(localStorage.getItem("userinfo"))
      data.text = '将' + userinfo.phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2') + '所绑定的账号注销！';
      data.thankText = '很遗憾无法继续为您提供服务，感谢你一直以来的陪伴！'
    });
    const data = reactive({
          text: '',
          thankText: '',

        },
    )

    const methodsMap = {
      cancelAccount: () => {
        req.request(API.cancel, {openid: localStorage.getItem("openid")}, {method: 'POST'}).then((res) => {
          localStorage.clear();
          sessionStorage.clear();
          router.push("/MyInfo")
        })
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.app_content {
  .warp_body {

    .iconDiv {
      text-align: center;
      padding: 20px;
    }

    .textDiv {
      padding-top: 20px;
      text-align: center;
    }

    .thankDiv {
      color: gray;
      padding-top: 10px;
      text-align: center;
      font-size: 14px;
    }

    .btnDiv {
      text-align: center;
      margin-top: 40px;
      padding: 10px;
    }
  }
}
</style>
